<template>
    <div class="cashierTrade" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select placeholder="请选择" v-model="form.deptCode" @change="changeDept" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in meta.depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-select placeholder="请选择" v-model="form.staffCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="staff in meta.staffList"
                                    :label="staff.staffName"
                                    :value="staff.staffCode"
                                    :key="staff.staffCode"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                size="small"
                type="primary"
                @click="searchHandleQuery"
                v-if="hasPrivilege('menu.report.cashier.open')"
                >查询</el-button
            >
            <el-button
                size="small"
                type="primary"
                @click="handleExport"
                v-if="hasPrivilege('menu.report.cashier.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="420"
            >
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="机构" width="200" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="姓名" width="300" prop="staffName" v-if="showColumn('staffName')" />
                <el-table-column
                    label="收银总金额"
                    width="160"
                    prop="tradeMoney"
                    key="tradeMoney"
                    v-if="showColumn('tradeMoney')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.tradeMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="退款总金额"
                    width="160"
                    prop="refundMoney"
                    key="refundMoney"
                    v-if="showColumn('refundMoney')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.refundMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="总计" width="160" prop="money" key="money" v-if="showColumn('money')">
                    <template slot-scope="scope">
                        <span>{{ countMoney(scope.row) | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="operate"
                    key="operate"
                    label="操作"
                    min-width="80"
                    header-align="center"
                    v-if="hasPrivilege('menu.report.cashier.detail') && showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfStartDate from '../../../components/EfStartDate';
import EfEndDate from '../../../components/EfEndDate';
import Decimal from 'decimal';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'CashierTrade',
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            emptyZero: true,
            form: {
                deptCode: '',
                staffCode: '',
                startTime: this.formatDate(new Date()),
                endTime: this.formatDate(new Date()),
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/report/cash/cashierTrade',
                queryCashierList: '/report/cash/cashierList',
            },
            current: {
                typeIdx: 3,
                deptCode: '',
            },
            meta: {
                depts: [],
                staffList: [],
            },
            showData: {},
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.meta.depts = rst;
        });
        const _params = { params: this.form };
        Util.queryTable(this, this.url.page, _params);
        this.queryStaffList();
    },
    methods: {
        countMoney(row) {
            const temMoney = Decimal(row.tradeMoney).sub(Decimal(row.refundMoney || 0));
            return temMoney;
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            const _this = this;
            UrlUtils.Export(_this, '收银员交易汇总', '/report/cash/export/cashierTrade', _this.form);
        },
        rowDetail(row) {
            const _params = row;
            _params.startTime = this.form.startTime;
            _params.endTime = this.form.endTime;
            Util.nameJump(this, 'menu.report.cashier.detail', ['报表管理', '收银交易汇总', '详情'], {
                form: _params,
                code: row.code,
            });
        },
        changeDept() {
            this.queryStaffList();
        },
        queryStaffList() {
            const _this = this;
            const _staffParams = { params: { deptCode: _this.form.deptCode } };
            Util.queryTable(_this, _this.url.queryCashierList, _staffParams, (data) => {
                _this.meta.staffList = data.data;
            });
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
    },
};
</script>

<style scoped>
.cashierTrade .el-form-item {
    margin-bottom: 0;
}
</style>
